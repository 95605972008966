import * as React from 'react';
import {
  Box,
  Typography,
  Modal,
  CardMedia,
  Divider,
  Icon,
  Button,
} from '@mui/material';

import { IBrokers } from './Finder';

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

interface Props {
  open: boolean;
  handleClose: () => void;
  broker: IBrokers;
}

export const Details: React.FC<Props> = ({ open, handleClose, broker }) => {
  console.log(broker);

  const handleClick = () => {
    const mobile = broker.mobile.replace('-', '').replace(' ', '').trim();
    console.log(mobile);

    const link = document.createElement('a');
    link.target = '_blank';
    link.href = `https://api.whatsapp.com/send/?phone=55${mobile}&text&type=phone_number&app_absent=0`;
    document.body.appendChild(link);
    link.click();
  };
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box display="flex" flexDirection="column" alignItems="center">
            {broker.img_user ? (
              <CardMedia
                component="img"
                src={`data:image/png;base64, ${broker.img_user}`}
                style={{
                  borderRadius: '50%',
                  width: '150px',
                  height: '150px',
                }}
              />
            ) : (
              <img
                src={`https://ui-avatars.com/api/?font-size=0.33&background=f37635&color=fff&name=${broker.name}`}
                style={{
                  borderRadius: '50%',
                  width: '150px',
                  height: '150px',
                }}
              />
            )}
            <Box marginTop={3} width="100%">
              <Typography variant="h6" component="h2" textAlign="center">
                {broker.name}
              </Typography>

              <Box
                display="flex"
                alignItems="center"
                textAlign="center"
                justifyContent="center"
                marginTop={1}
              >
                <Icon color="primary">pin_drop</Icon>
                <Typography marginLeft={2}>
                  {broker.city} / {broker.state}
                </Typography>
              </Box>

              <Divider style={{ margin: '8px 0', width: '100%' }} />

              <Box>
                <Box display="flex" flexDirection="column">
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Icon color="action">star</Icon>
                    <Typography
                      variant="subtitle2"
                      marginLeft={1}
                      marginRight={1}
                    >
                      Qualificações
                    </Typography>
                    <Icon color="action">star</Icon>
                  </Box>

                  <Typography variant="body2" marginTop={1}>
                    {broker.professional_qualification}
                  </Typography>

                  <Typography color="secondary" variant="body1" marginTop={1}>
                    Avaliações Realizadas {broker.num_ptam}
                  </Typography>
                </Box>
              </Box>

              <Divider style={{ margin: '8px 0', width: '100%' }} />

              <Box marginTop={2}>
                <Box display="flex" alignItems="center">
                  <Typography
                    variant="subtitle2"
                    marginLeft={1}
                    marginRight={1}
                  >
                    Entre em Contatos
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" marginTop={2}>
                  <Icon color="primary">email</Icon>
                  <Typography marginLeft={2}>{broker.email}</Typography>
                </Box>

                <Box display="flex" alignItems="center" marginTop={1}>
                  <Icon color="primary">phone</Icon>
                  <Typography marginLeft={2}>{broker.mobile}</Typography>
                </Box>

                <Divider style={{ margin: '8px 0', width: '100%' }} />
              </Box>
              <Button
                variant="contained"
                fullWidth
                style={{ marginTop: '8px' }}
                onClick={handleClick}
              >
                <Typography variant="button" color="#fff">
                  Entrar em contato
                </Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
