/* eslint-disable @typescript-eslint/no-explicit-any */
import { api } from '../shared/services/api';

interface IBrokers {
  avatar_id: number;
  city: string;
  cnai: string;
  company_id: number;
  creci: string;
  email: string;
  expire_at: Date;
  expired: boolean;
  id: number;
  mobile: string;
  name: string;
  num_ptam: string;
  phone: string;
  professional_experience: string;
  professional_qualification: string;
  ptam_quantity: number;
  regiao_creci: string;
  site: string;
  state: string;
  img_user: string;
}

interface IResponse {
  users: IBrokers[];
  count: string;
}

const getAllActiveBrokers = () => {
  try {
    const result = api.get<IResponse>('/brokers/states');

    return result;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export { getAllActiveBrokers };
