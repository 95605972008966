import { Routes, Route, Navigate } from 'react-router-dom';

import { Home, Finder } from '../pages';
export const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/home" element={<Home />} />

      <Route path="/finder" element={<Finder />} />

      <Route path="*" element={<Navigate to="/home" />} />
    </Routes>
  );
};
