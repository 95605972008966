import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { BaseLayoutPage } from '../../shared/layouts';

import background from '../../assets/background.svg';
import atencao from '../../assets/atencao.svg';

export const Home: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <BaseLayoutPage>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexDirection="column"
        height={smDown ? '85%' : '100%'}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width={smDown ? '100%' : '80%'}
          marginTop={smDown ? 2 : 10}
          flexDirection={smDown ? 'column' : 'row'}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent={smDown ? 'space-evenly' : 'space-between'}
            alignItems="flex-start"
          >
            <Box display="flex" alignItems="center">
              <Typography variant="h4" marginRight={2}>
                👏
              </Typography>
              <Typography variant={smDown ? 'h6' : 'h4'} color="primary">
                Sejam muito bem vindos !!
              </Typography>
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              alignItems={smDown ? 'center' : 'flex-start'}
              marginTop={smDown ? 0 : 4}
            >
              <Typography variant="h6" color={theme.palette.text.primary}>
                Você está precisando de uma
              </Typography>
              <Typography variant={smDown ? 'h5' : 'h3'} color="primary">
                Avaliação Imobiliária ?
              </Typography>
            </Box>

            <Button
              variant="contained"
              fullWidth={smDown ? true : false}
              onClick={() => navigate('/finder')}
              style={{
                marginTop: smDown ? '24px' : '48px',
                marginBottom: '24px',
              }}
            >
              <Typography variant="button" color="#fff">
                Encontre um avaliador
              </Typography>
            </Button>
          </Box>

          <Box textAlign="center">
            <img src={background} alt="Background image" width="70%" />
          </Box>
        </Box>

        <Box
          display="flex"
          alignItems="center"
          flexDirection={smDown ? 'column' : 'row'}
          marginTop={4}
          width={smDown ? '100%' : '80%'}
          marginBottom={4}
        >
          <img
            src={atencao}
            alt="Background image"
            width={smDown ? '60px' : '80px'}
            style={{
              marginBottom: smDown ? '24px' : '0',
            }}
          />

          <Typography
            marginLeft={2}
            variant="subtitle2"
            color={theme.palette.text.primary}
          >
            Pensando em vender ou comprar um imóvel? Contrate um avaliador de
            imóveis certificado para obter uma avaliação precisa e segura. As
            vantagens incluem precisão, legitimidade, economia, agilidade no
            processo e suporte na negociação. Solicite um orçamento sem
            compromisso e valorize seu patrimônio adequadamente.
          </Typography>
        </Box>
      </Box>
    </BaseLayoutPage>
  );
};
