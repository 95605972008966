import { createTheme } from '@mui/material';

export const LightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#f37635',
      dark: '#f37635',
      light: 'rgba(243, 152, 53, 0.2)',
      contrastText: '#333',
    },
    secondary: {
      main: '#1c5e85',
      dark: '#1c5e85',
      light: '#1c5e85',
      contrastText: '#fff',
    },
    text: {
      primary: '#333',
    },
    background: {
      paper: '#fff',
      default: '#eaeaea',
    },
  },
  typography: {
    allVariants: {
      color: 'black',
    },
  },
});
