import { BrowserRouter } from 'react-router-dom';

import { AppThemeProvider } from './shared/contexts/ThemeContext';
import { DrawerProvider } from './shared/contexts';

import { AppRoutes } from './routes';

export const App: React.FC = () => (
  <AppThemeProvider>
    <DrawerProvider>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </DrawerProvider>
  </AppThemeProvider>
);
