import React, { useState, FC, MouseEvent } from 'react';
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Container,
  Button,
  useTheme,
} from '@mui/material';

import { useNavigate } from 'react-router-dom';

import MenuIcon from '@mui/icons-material/Menu';

import logo from '../../assets/logo.png';

import pages from '../utils/menus';

interface IProps {
  children: React.ReactNode;
}

const BaseLayoutPage: FC<IProps> = ({ children }) => {
  const theme = useTheme();

  const navigate = useNavigate();

  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <Box>
      <AppBar
        position="static"
        style={{
          background: theme.palette.primary.light,
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages.map(page => (
                  <MenuItem key={page.menu} onClick={handleCloseNavMenu}>
                    <Button
                      key={page.menu}
                      onClick={() => navigate(page.path)}
                      variant="text"
                      sx={{ color: 'white', display: 'block' }}
                    >
                      <Typography textAlign="center" color="primary">
                        {page.menu}
                      </Typography>
                    </Button>
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              <Box width="30%" display="flex" alignItems="center">
                <img src={logo} alt="Logo Avalie" width="180px" />
              </Box>
              {pages.map(page => (
                <Button
                  key={page.menu}
                  onClick={() => navigate(page.path)}
                  variant="text"
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  <Typography textAlign="center" color="primary">
                    {page.menu}
                  </Typography>
                </Button>
              ))}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Box padding={2} height="calc(100vh - 100px)">
        {children}
      </Box>
    </Box>
  );
};
export { BaseLayoutPage };
