export default [
  {
    menu: 'Home',
    path: '/home',
  },
  {
    menu: 'Encontre um avaliador',
    path: '/finder',
  },
];
