/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import _ from 'lodash';

import { useState, useEffect, MouseEvent } from 'react';

import {
  Box,
  useMediaQuery,
  useTheme,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Button,
  Icon,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  LinearProgress,
  CardMedia,
  IconButton,
} from '@mui/material';

import { BaseLayoutPage } from '../../shared/layouts';

import { getAllActiveBrokers } from '../../api/avalie';
import { statesEnum } from '../../shared/utils/statesEnum';

import { Details } from './Details';

interface ISelectInterface {
  label: string;
  value: string;
}

interface IStatesAndCities {
  state: string;
  cities: string[];
}

export interface IBrokers {
  avatar_id: number;
  city: string;
  cnai: string;
  company_id: number;
  creci: string;
  email: string;
  expire_at: Date;
  expired: boolean;
  id: number;
  mobile: string;
  name: string;
  num_ptam: string;
  phone: string;
  professional_experience: string;
  professional_qualification: string;
  ptam_quantity: number;
  regiao_creci: string;
  site: string;
  state: string;
  img_user: string;
}

export const Finder: React.FC = () => {
  const theme = useTheme();

  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const [brokersData, setBrokersData] = useState<IBrokers[]>([]);

  const [stateSelected, setStateSelected] = useState('');
  const [citySelected, setCitySelected] = useState('');
  const [cities, setCities] = useState<ISelectInterface[]>([]);
  const [statesAndCities, setStatesAndCities] = useState<IStatesAndCities[]>(
    [],
  );
  const [states, setStates] = useState<ISelectInterface[]>([]);

  const [brokers, setBrokers] = useState<IBrokers[]>([]);
  const [broker, setBroker] = useState({} as IBrokers);
  const [loading, setLoading] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);

  const handleChangeState = (event: SelectChangeEvent) => {
    setStateSelected(event.target.value as string);

    setCities([]);
    setBrokers([]);

    const result = statesAndCities.find(
      (item: IStatesAndCities) => item.state === event.target.value,
    );

    const _data = result?.cities.map(item => {
      return {
        label: item,
        value: item,
      };
    });

    if (_data) {
      _data.push({
        label: 'Todos',
        value: '*',
      });

      _data.sort((a, b) => (a.value > b.value ? 1 : -1));

      setCities(_data);
    }
  };

  const handleChangeCity = (event: SelectChangeEvent) => {
    setCitySelected(event.target.value as string);
    setBrokers([]);
  };

  const handleSubmit = () => {
    if (citySelected !== '*') {
      const result = brokersData.filter(
        broker =>
          broker.state === stateSelected && broker.city === citySelected,
      );
      setBrokers(result);
    } else {
      const result = brokersData.filter(
        broker => broker.state === stateSelected,
      );
      setBrokers(result);
    }
  };

  const handleDetails = (_brokerData: IBrokers) => {
    setOpenDetails(!openDetails);
    setBroker(_brokerData);
  };

  const handleCloseModal = () => {
    setOpenDetails(!openDetails);
  };

  useEffect(() => {
    setLoading(true);
    getAllActiveBrokers().then(({ data }) => {
      const statesFiltered: ISelectInterface[] = [];

      data.users.forEach((item: any) => {
        if (item.state) {
          const idxState = statesFiltered.findIndex(
            (it: ISelectInterface) => it.value === item.state,
          );
          if (idxState < 0) {
            statesFiltered.push({
              value: item.state,
              label: statesEnum[item.state],
            });
          }
        }
      });

      statesFiltered.sort((a, b) => (a.value > b.value ? 1 : -1));

      const citiesAndStatesFiltered: IStatesAndCities[] = [];
      statesFiltered.forEach(item => {
        const dataFiltered = data.users.filter(
          (row: any) => row.state === item.value,
        );

        const _cities = dataFiltered.map((item: any) => item.city);
        citiesAndStatesFiltered.push({
          state: item.value,
          cities: _.uniq(_cities),
        });
      });

      setStates(statesFiltered);
      setStatesAndCities(citiesAndStatesFiltered);
      setLoading(false);
      setBrokersData(data.users);
    });
  }, []);

  return (
    <BaseLayoutPage>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexDirection={smDown ? 'column' : 'row'}
      >
        {openDetails && (
          <Details
            open={openDetails}
            handleClose={handleCloseModal}
            broker={broker}
          />
        )}
        <FormControl
          fullWidth
          style={{
            marginRight: smDown ? '0' : '4px',
          }}
        >
          <InputLabel id="estado-label">Estado</InputLabel>
          <Select
            fullWidth
            labelId="estado-label"
            id="estado-simple-select"
            value={stateSelected}
            label="Estado"
            onChange={handleChangeState}
            disabled={loading}
          >
            {states.map((state: ISelectInterface) => (
              <MenuItem key={state.value} value={state.value}>
                {state.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl
          fullWidth
          style={{
            marginLeft: smDown ? '0' : '4px',
            marginTop: smDown ? '12px' : '0',
          }}
        >
          <InputLabel id="cidade-label">Cidade</InputLabel>
          <Select
            fullWidth
            labelId="cidade-label"
            id="cidade-simple-select"
            value={citySelected}
            label="Cidade"
            onChange={handleChangeCity}
            disabled={!cities.length}
          >
            {cities.map(city => (
              <MenuItem key={city.value} value={city.value}>
                {city.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button
          fullWidth
          variant="outlined"
          style={{
            marginLeft: smDown ? '0' : '8px',
            height: '56px',
            width: smDown ? '100%' : '56px',
            marginTop: smDown ? '12px' : '0',
            display: 'flex',
            alignItems: 'center',
          }}
          onClick={handleSubmit}
        >
          <Icon>person_search</Icon>
          {smDown && (
            <Typography variant="button" color="primary" marginLeft="16px">
              Buscar avaliador
            </Typography>
          )}
        </Button>
      </Box>
      {loading ? (
        <Box marginTop={3}>
          <LinearProgress />
        </Box>
      ) : brokers.length <= 0 ? (
        <Box marginTop={3}>
          <Typography variant="subtitle1" color="primary">
            Não Existem avaliadores para essa consulta
          </Typography>
        </Box>
      ) : (
        <Box marginTop={3}>
          <Typography variant="caption" color="primary">
            Lista de avaliadores
          </Typography>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  {!smDown && <TableCell align="center"></TableCell>}
                  <TableCell align="left">Avaliador</TableCell>
                  {!smDown && <TableCell align="right">Telefone</TableCell>}
                  {!smDown && <TableCell align="right">E-mail</TableCell>}

                  <TableCell align={!smDown ? 'right' : 'center'}>
                    Avaliações realizadas
                  </TableCell>
                  <TableCell align="right">Detalhes</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {brokers.map((broker: IBrokers) => (
                  <TableRow
                    key={Math.random()}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    {!smDown && (
                      <TableCell component="th" scope="row">
                        {broker.img_user ? (
                          <CardMedia
                            component="img"
                            src={`data:image/png;base64, ${broker.img_user}`}
                            style={{
                              borderRadius: '50%',
                              width: '50px',
                              height: '50px',
                            }}
                          />
                        ) : (
                          <img
                            src={`https://ui-avatars.com/api/?font-size=0.33&background=f37635&color=fff&name=${broker.name}`}
                            style={{
                              borderRadius: '50%',
                              width: '50px',
                            }}
                          />
                        )}
                      </TableCell>
                    )}

                    <TableCell align="left">{broker.name}</TableCell>
                    {!smDown && (
                      <TableCell align="right">{broker.mobile}</TableCell>
                    )}

                    {!smDown && (
                      <TableCell align="right">{broker.email}</TableCell>
                    )}

                    <TableCell align={!smDown ? 'right' : 'center'}>
                      {broker.num_ptam}
                    </TableCell>
                    <TableCell align="right">
                      <Box position="relative">
                        <IconButton
                          color="primary"
                          onClick={() => handleDetails(broker)}
                        >
                          <Icon>content_paste_search</Icon>
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </BaseLayoutPage>
  );
};
